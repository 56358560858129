import Jobs from '@/views/App/Jobs.vue';
import JobDetails from '@/views/App/JobDetails.vue';
import AddJobs from '@/views/App/AddJobs.vue';

export default [
  {
    meta: { layout: 'dashboard', requiresAuth: true },
    path: '/jobs',
    name: 'Jobs',
    component: Jobs,
    title: 'Jobs',

  },
  {
    meta: { layout: 'dashboard', requiresAuth: true, previousRoute: '/jobs' },
    path: '/job/:id',
    name: 'Job-Details',
    component: JobDetails,
    title: 'Job-Details',
    props: true
  },
  {
    meta: { layout: 'dashboard', requiresAuth: true, previousRoute: '/jobs' },
    path: '/jobs/add-site',
    name: 'Add-Site',
    component: AddJobs,
  },
  {
    meta: { layout: 'dashboard', requiresAuth: true, previousRoute: '/jobs' },
    path: '/jobs/edit-site/:id',
    name: 'Edit-Site',
    component: AddJobs,
    props: true
  },
];
