<template>
  <AppCard
    @removeClicked="removeSection"
    @cloneClicked="cloneSection"
    removable
    draggable
    cloneable
    collapsable
    titleType="medium"
    icon="Bars4"
    class="w-full my-4"
    ><template #title>{{ localSection.title }}</template>

    <div class="grid grid-cols-2 w-full">
      <TextInput
        class="mx-4 mt-2"
        label="Section Title"
        v-model="localSection.title"
      />
    </div>

    <TextInput
      class="mx-4 mt-2"
      label="Description"
      multiline
      v-model="localSection.description"
    />

    <draggable
      class="list-group"
      tag="div"
      handle=".drag-handle"
      :component-data="{
        type: 'transition-group',
        name: !drag ? 'flip-list' : null
      }"
      group="questions"
      :list="localSection.questions"
      v-bind="dragOptions"
      @start="drag = true"
      @end="drag = false"
      item-key="key"
    >
      <div v-for="(element, index) in localSection.questions" :key="index">
        <QuestionCard
          @removeQuestion="removeQuestion"
          @cloneQuestion="cloneQuestion"
          @updateQuestion="updateQuestion"
          :key="index"
          class="my-4 mx-4"
          :question="generateKey(element)"
          :questions="localSection.questions"
          :showKeys="showKeys"
          :sectionKey="localSection.key"
        >
        </QuestionCard>
      </div>
      <AppCard
        v-if="!localSection.questions || localSection.questions.length == 0"
        missing
        class="my-4 mx-4"
        ><template #title>Drop questions here</template>
      </AppCard>
    </draggable>

    <template #bottom>
      <div class="flex justify-between border-t p-4">
        <span
          ><span class="font-bold mr-1">Questions:</span>
          {{ questionCount }}</span
        >
      </div>
    </template>
  </AppCard>
</template>
<script>
import { VueDraggableNext } from 'vue-draggable-next';
import QuestionCard from './QuestionCard.vue';
import { v4 as uuid } from 'uuid';

export default {
  components: {
    draggable: VueDraggableNext,
    QuestionCard
  },
  props: {
    section: {},
    showKeys: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      drag: false,
      localSection: {}
    };
  },
  watch: {
    localSection: {
      handler(val) {
        this.$emit('updateSection', val);
      },
      deep: true
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost'
      };
    },
    questionCount() {
      return this.localSection?.questions?.length;
    }
  },
  mounted() {
    this.refreshSection();
  },
  methods: {
    refreshSection() {
      this.localSection = JSON.parse(JSON.stringify(this.section));
    },
    updateQuestion(question) {
      // if question title is empty, make title same as type
      if (!question.title) {
        // capitalize first letter
        question.title =
          question.type.charAt(0).toUpperCase() + question.type.slice(1);
      }
      this.localSection.questions = this.localSection.questions.map((q) => {
        if (q.key == question.key) {
          return question;
        }
        return q;
      });
    },
    removeQuestion(questionKey) {
      let index = this.section.questions.findIndex((i) => i.key == questionKey);

      this.$emit('updateSection', {
        ...this.localSection,
        ...{
          questions: this.localSection.questions.splice(index, 1)
        }
      });
    },

    cloneQuestion(questionKey) {
      let index = this.section.questions.findIndex((i) => i.key == questionKey);
      let question = this.section.questions[index];
      let newQuestion = {
        ...JSON.parse(JSON.stringify(question)),
        key: uuid(),
        title: question.title + ' (copy)'
      };

      this.$emit('updateSection', {
        ...this.localSection,
        ...{
          questions: this.localSection.questions.splice(index, 0, newQuestion)
        }
      });
    },
    removeSection() {
      this.$emit('removeSection', this.section.key);
    },
    cloneSection() {
      this.$emit('cloneSection', this.section.key);
    },
    generateKey(e) {
      if (e.key) return e;
      e.key = uuid();

      return e;
    }
  }
};
</script>
