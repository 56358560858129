import Documents from '@/views/App/Documents/AppDocuments.vue';
import DocumentDetails from '@/views/App/Documents/DocumentDetails.vue';
import A4Document from '@/views/App/Documents/A4Document.vue';
import DocumentTemplates from '@/views/App/Documents/DocumentTemplates.vue';
import EditDocumentTemplate from '@/views/App/Documents/EditDocumentTemplate.vue';

export default [
    {
        meta: { layout: 'dashboard', requiresAuth: true },
        path: '/documents',
        name: 'Documents',
        component: Documents,
        title: 'Documents',
    },
    {
        meta: { layout: 'dashboard', requiresAuth: true, previousRoute: '/documents' },
        path: '/documents/view/:uid',
        name: 'DocumentDetails',
        component: DocumentDetails,
        title: 'Document Details',
        props: true
    },
    {
        path: '/documents/a4/:uid',
        name: 'A4 Document',
        component: A4Document,
        title: 'Print Document',
        meta: { layout: 'print-page', requiresAuth: true },
        props: true

    },
    {
        path: '/documents/templates',
        name: 'Document Templates',
        component: DocumentTemplates,
        title: 'Document Templates',
        meta: { layout: 'dashboard', requiresAuth: true }
    },
    {
        path: '/documents/templates/:uid',
        name: 'Edit Document Template',
        component: EditDocumentTemplate,
        title: 'Edit Document Template',
        meta: { layout: 'dashboard', requiresAuth: true },
        props: true
    }
];
