<template>
  <div
    class="bg-white rounded-md border p-4 relative"
    :class="{
      'hover:-translate-y-[2px] transition-all shadow  duration-300 cursor-pointer hover:shadow-md':
        clickable,
      'opacity-25 outline-dashed': missing,
      'shadow-sm': !condensed,
      'border border-gray-200': condensed,
      'p-2': collapsable
    }"
  >
    <div
      v-if="hasTitle || hasChip || collapsable || hasRight"
      @click="collapsable ? collapse() : null"
      class="flex items-center select-none"
      :class="{ 'cursor-pointer p-2': collapsable }"
    >
      <div v-if="icon" class="p-1 mr-2">
        <IconPack :type="icon" class="w-8 h-8 text-primary-500" />
      </div>

      <div class="w-full flex items-center justify-between">
        <div>
          <AppTitle :type="titleType">
            <slot name="title" />
          </AppTitle>
          <span class="text-xs">
            <slot name="subtitle" />
          </span>
          <p class="text-base">
            <slot name="text" />
          </p>
        </div>

        <div v-if="hasRight" class="">
          <slot name="topRight" />
        </div>
      </div>

      <div class="absolute h-8 font-medium shadow-none -top-2 -right-5 z-20">
        <slot name="chip" />
      </div>

      <div v-if="collapsable">
        <IconPack
          type="ChevronDown"
          class="w-6 h-6 mx-1 transition-all duration-300 rounded-full text-gray-500 p-1"
          :class="{ 'rotate-180 transition-all duration-300': collapsed }"
        />
      </div>

      <div v-if="cloneable">
        <div
          @click.stop="clone($event)"
          v-tippy="{
            content: 'Clone',
            placement: 'bottom'
          }"
          class="cursor-pointer"
        >
          <IconPack
            type="DocumentDuplicate"
            class="mx-1 w-5 h-5 p-1 text-white bg-secondary-500 rounded-full"
          />
        </div>
      </div>

      <div v-if="draggable">
        <div
          v-tippy="{
            content: 'Hold to drag',
            placement: 'bottom'
          }"
          class="drag-handle cursor-move"
          @click.stop
        >
          <IconPack
            type="ChevronUpDown"
            class="mx-1 w-5 h-5 p-1 text-white bg-primary-500 rounded-full"
          />
        </div>
      </div>

      <div v-if="removable">
        <IconPack
          v-tippy="{
            content: 'Remove',
            placement: 'bottom'
          }"
          @click.stop="remove($event)"
          type="XMark"
          class="mx-1 w-5 h-5 p-1 text-white bg-red-400 rounded-full"
        />
      </div>
    </div>

    <div v-if="!collapsable || !collapsed">
      <slot />
    </div>

    <slot name="bottom" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      collapsed: !this.open
    };
  },
  props: {
    clickable: { type: Boolean, default: false },
    cloneable: { type: Boolean, default: false },
    collapsable: { type: Boolean, default: false },
    removable: { type: Boolean, default: false },
    draggable: { type: Boolean, default: false },
    missing: { type: Boolean, default: false },
    titleType: { type: String, default: undefined },
    icon: { type: String, default: undefined },
    condensed: { type: Boolean, default: false },
    open: { type: Boolean, default: false }
  },
  computed: {
    hasRight() {
      return !!this.$slots.topRight;
    },
    hasTitle() {
      return !!this.$slots.title;
    },
    hasChip() {
      return !!this.$slots.chip;
    }
  },
  methods: {
    remove(event) {
      event.preventDefault();

      //TODO: confirmation
      this.$emit('removeClicked');
    },
    clone(event) {
      event.preventDefault();

      //TODO: confirmation
      this.$emit('cloneClicked');
    },
    collapse() {
      this.collapsed = !this.collapsed;
      this.$emit('toggle-collapse', this.collapsed);
    }
  }
};
</script>
