import Dashboard from '@/views/App/Dashboard.vue';
import InboxItems from '@/views/App/InboxItems.vue';

export default [
  {
    meta: { layout: 'dashboard', requiresAuth: true },
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    title: 'Dashboard',
    redirect: '/jobs',
  },
  {
    meta: { layout: 'dashboard', requiresAuth: true },
    path: '/inbox',
    name: 'Inbox',
    component: InboxItems,
    title: 'Inbox',
  }
];
