import store from '..';

export default {
  async createLocation({ commit, rootGetters }, location) {
    const { address_line, suburb, city, stand_number, postal_code, province, longitude, latitude, buildingName } = location;
    console.log('Creating location', location);
    commit('SET_LOADING', true);
    return store.$api.post(`${import.meta.env.VITE_API_URL}/locations`, {
      data: {
        uid: '',
        address_line: address_line,
        suburb: suburb,
        city: city,
        stand_number: stand_number,
        postal_code: postal_code,
        province: province,
        longitude: longitude.toString(),
        latitude: latitude.toString(),
        building_name: buildingName,
      }
    }, {
      headers: {
        Authorization: 'Bearer ' + rootGetters['Authentication/token'],
      },
    }).then((response) => {
      if (response.status === 200) {
        console.log('Location created', response.data.data);
        commit('SET_LOCATION', response.data.data);
        commit('ADD_OR_UPDATE_LOCATION', response.data.data);
        commit('SET_LOADING', false);
        return response;
      }
    }
    ).catch((error) => {
      console.error(error);
      throw error;
    });
  },
  async getLocation({ commit, rootGetters }, locationId) {
    commit('SET_LOADING', true);
    return await store.$api.get(`${import.meta.env.VITE_API_URL}/locations`, {
      headers: {
        Authorization: 'Bearer ' + rootGetters['Authentication/token'],
      },
      params: {
        'filters[uid][$eq]': locationId,
      },
    }).then((response) => {
      if (response.status === 200) {
        // console.log('Location retrieved', response.data.data[0]);
        commit('SET_LOCATION', response.data.data[0]);
        commit('ADD_OR_UPDATE_LOCATION', response.data.data[0]);
        commit('SET_LOADING', false);
        return response;
      }
    }
    ).catch((error) => {
      console.error(error);
      throw error;
    });
  }
};
