
export default {
  SET_PLANTS(state, payload) {
    state.plants = payload;
  },

  SET_FETCHING(state, payload) {
    state.fetching = payload;
  },

  SET_HAS_APP_COUNT(state, payload) {
    state.hasAppCount = payload;
  },

  UPDATE_PLANT(state, payload) {
    state.plants = state.plants.map((plant) => {
      if (plant.id === payload.id) {
        return payload;
      }
      return plant;
    });
  },

  SET_AREAS(state, payload) {
    state.areas = payload;
  },

};
