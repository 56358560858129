import store from '..';
import qs from 'qs';

export default {
  getPlants({ commit, rootGetters }, { start, limit, filters, sort }) {
    return new Promise((resolve, reject) => {
      commit('SET_FETCHING', true);
      store.$api
        .get(`${import.meta.env.VITE_API_URL}/connect/plants?${filters ? qs.stringify(filters) : ''}`, {
          params: {
            'pagination[start]': start,
            'pagination[limit]': limit,
            'sort': sort
          },
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token']
          }
        })
        .then((response) => {
          if (response.status === 200) {
            commit('SET_PLANTS', response.data.data);
            commit('SET_FETCHING', false);
            resolve(response);
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  getPlantById({ commit, rootGetters }, id) {
    return new Promise((resolve, reject) => {
      store.$api
        .get(`${import.meta.env.VITE_API_URL}/connect/plants/${id}`, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token']
          }
        })
        .then((response) => {
          if (response.status === 200) {
            commit('UPDATE_PLANT', response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  updatePlant({ commit, rootGetters }, { id, data }) {
    commit('SET_FETCHING', true);
    return new Promise((resolve, reject) => {
      store.$api
        .put(`${import.meta.env.VITE_API_URL}/connect/plants/${id}`, {
          data: data
        }, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token']
          }
        })
        .then((response) => {
          if (response.status === 200) {
            commit('UPDATE_PLANT', response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        }).finally(() => {
          commit('SET_FETCHING', false);
        });
    });
  },

  getAreas({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      store.$api
        .get(`${import.meta.env.VITE_API_URL}/connect/areas`, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token']
          }
        })
        .then((response) => {
          if (response.status === 200) {
            commit('SET_AREAS', response.data.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  sendInvite({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      store.$api
        .post(
          import.meta.env.VITE_API_URL + `/connect/plants/${payload.plantId}/invite`,

          payload,
          {
            params: {},
            headers: {
              Authorization: 'Bearer ' + rootGetters['Authentication/token']
            }
          }
        )
        .then((response) => {
          console.log('sendInvite', response);
          if (response.status === 200) {
            //     commit('SET_PLANTS', response.data.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  refreshPlant({ rootGetters }, id) {
    return new Promise((resolve, reject) => {
      store.$api
        .get(`${import.meta.env.VITE_API_URL}/connect/plants/${id}/refresh`, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token']
          }
        })
        .then((response) => {
          if (response.status === 200) {
            console.log('refreshPlant', response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }

};
