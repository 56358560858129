<template>
  <transition name="scale-quick" mode="out-in" appear>
    <div
      key="spinner"
      class="self-center scrollbar-hide h-screen flex"
      v-if="subscriptionPlans === null"
    >
      <AppSpinner class="h-20 text-primary-500 self-center" />
    </div>

    <div v-else key="subscriptionPlans" class="flex flex-col w-full">
      <div class="w-full">
        <AppTitle type="large" class="text-center mb-0">Choose a plan</AppTitle>

        <div
          v-if="!company?.attributes?.plan_code"
          class="w-full text-center mb-4 max-w-xs m-auto text-lg"
        >
          Pick a plan that suits your business. You can change your plan at any
          time.
        </div>
        <div v-else class="w-full text-center mb-4 max-w-screen-md m-auto">
          Upgrades are instant, downgrades will be effective from the next
          billing period.
        </div>
      </div>

      <WarningCard
        v-if="!company.attributes"
        type="Info"
        class="max-w-screen-sm mx-auto w-full my-6"
      >
        You need to set up your company details in the app before choosing a
        subscription plan.

        <router-link to="/invite/get-the-app"
          ><AppButton
            class="mt-2 bg-secondary-500 border-none shadow-none hover:bg-secondary-600"
            >Get The App</AppButton
          ></router-link
        >
      </WarningCard>

      <AppCard
        v-if="company?.attributes"
        class="w-full max-w-md m-auto mb-4 relative p-8"
      >
        <AppTitle class="text-center mb-4 text-xl"
          >{{ isTrial ? 'Trial' : 'Plan' }} Usage</AppTitle
        >

        <div class="text-center my-2 mb-4">{{ company?.attributes?.name }}</div>

        <div class="lg:absolute lg:-top-4 lg:-right-4 mb-2 text-center">
          <AppChip
            v-if="company?.attributes?.subscription_status === 'active'"
            class="bg-green-500"
            >Active</AppChip
          >
          <AppChip
            v-if="company?.attributes?.subscription_status === 'cancelled'"
            class="bg-red-500"
            >Cancelled</AppChip
          >

          <AppChip v-else-if="isActiveTrial" class="bg-green-500"
            >Trial Active</AppChip
          >

          <AppChip v-else-if="isTrial && !isActiveTrial" class="bg-red-500"
            >Expired</AppChip
          >
        </div>

        <div class="flex flex-col text-base gap-4">
          <div class="flex flex-row gap-1">
            <span class="text-3xl mr-2">⚡️ </span>

            <div class="font-bold">
              COC Documents
              <div class="opacity-50 text-xs">last 30 days</div>
            </div>

            <div
              class="ml-auto m-1 bg-primary-500 text-white p-2s rounded-full text-xs font-bold w-10 h-10 flex justify-center items-center"
            >
              {{ cocCountLast30Days || 0 }}
            </div>
          </div>
          <div class="flex flex-row gap-1">
            <span class="text-3xl mr-2">📝 </span>

            <div class="font-bold">
              Total Documents
              <div class="opacity-50 text-xs">last 30 days</div>
            </div>

            <div
              class="ml-auto m-1 bg-primary-500 text-white p-2s rounded-full text-xs font-bold w-10 h-10 flex justify-center items-center"
            >
              {{ documentCount30Days || 0 }}
            </div>
          </div>
          <div class="flex flex-row justify-between gap-8">
            <div class="font-bold">
              <span class="text-3xl mr-2">🙋‍♂️</span>
              Team members
            </div>
            <div
              class="ml-auto m-1 bg-primary-500 text-white p-2s rounded-full text-xs font-bold w-10 h-10 flex justify-center items-center"
            >
              {{ company.attributes?.users?.data?.length }}
            </div>
          </div>
          <div v-if="isTrial" class="flex flex-row justify-between gap-8">
            <div class="font-bold">
              <span class="text-3xl mr-2">🗓️</span> Trial days remaining
            </div>

            <div
              class="ml-auto m-1 bg-primary-500 text-white p-2s rounded-full text-xs font-bold w-10 h-10 flex justify-center items-center"
              :class="{
                'bg-red-500':
                  moment(company.attributes?.expiry).diff(moment(), 'days') <
                  10,
                'bg-yellow-600':
                  moment(company.attributes?.expiry).diff(moment(), 'days') <=
                  10,
                'bg-green-500':
                  moment(company.attributes?.expiry).diff(moment(), 'days') > 10
              }"
            >
              {{
                moment(company.attributes?.expiry).diff(moment(), 'days') > 0
                  ? moment(company.attributes?.expiry).diff(moment(), 'days')
                  : 0
              }}
            </div>
          </div>

          <router-link
            v-if="company?.attributes?.subscription_status === 'active'"
            to="/company"
          >
            <AppButton class="mt-2"
              ><template #icon>
                <IconPack
                  type="CreditCard"
                  class="cursor-pointer h-6 w-6 text-white" /></template
              >Update Card Information</AppButton
            ></router-link
          >
        </div>
      </AppCard>

      <div class="flex flex-row flex-wrap max-w-screen-xl m-auto">
        <div
          class="w-full xl:w-1/2"
          v-for="(plan, index) in subscriptionPlans"
          :key="index"
        >
          <div class="p-2">
            <SubscriptionCard class="w-full" :plan="plan" />
          </div>
        </div>
      </div>

      <router-link
        v-if="company.attributes?.subscription_status === 'active'"
        to="/subscription/cancel"
      >
        <div
          key="cancelSubscription"
          class="cursor-pointer opacity-80 mt-10 m-auto text-center font-bold text-red-700"
        >
          Cancel Subscription
        </div>
      </router-link>

      <div class="opacity-80 mt-32 m-auto text-center">
        Plans are billed pro-rata and invoiced on the first of every month.
        Cancel any time ✅
        <br />
        Secure payments managed by Paystack.
      </div>

      <div class="w-full mt-4">
        <!-- <img
          src="https://energy-warehouse.s3.af-south-1.amazonaws.com/website/payfast-logo-dark.svg"
          class="w-32 m-auto"
        /> -->
        <img
          src="https://energy-warehouse.s3.af-south-1.amazonaws.com/website/paystack-logo.svg"
          class="w-32 m-auto"
        />
      </div>
    </div>
  </transition>
</template>
<script>
import WarningCard from '@/components/EW/WarnCard.vue';
import SubscriptionCard from '@/components/Subscriptions/Card.vue';
import { event } from 'vue-gtag';
export default {
  data() {
    return {
      documentCount30Days: null,
      cocCountLast30Days: null
    };
  },
  components: {
    SubscriptionCard,
    WarningCard
  },
  computed: {
    subscriptionPlans() {
      if (!this.$store.getters['Subscriptions/plans']?.length) return null;
      return this.$store.getters['Subscriptions/plans']
        ?.slice()
        ?.sort((a, b) => a.amount_cents - b.amount_cents)
        ?.filter((plan) => !plan.archived);
    },
    company() {
      return this.$store.getters['Company/company'];
    }
  },

  mounted() {
    this.$store.dispatch('Authentication/getUserProfile');

    event('view-subscription-plans', { method: 'Google' });

    this.$store
      .dispatch('Documents/getLastMonthDocumentUsage')
      .then((res) => {
        this.documentCount30Days = res.data?.meta?.pagination?.total;
      })
      .catch((err) => {
        console.log(err);
      });

    this.$store
      .dispatch('Documents/getLastMonthDocumentUsage', {
        name: 'Electrical COC'
      })
      .then((res) => {
        this.cocCountLast30Days = res.data?.meta?.pagination?.total;
      })
      .catch((err) => {
        console.log(err);
      });

    this.$store.dispatch('Company/getCompany');
    this.$store.dispatch('Subscriptions/getPlans');
  }
};
</script>
