<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <AppCard
    class="shadow hover:shadow-lg ease-in-out transform hover:-translate-y-2 transition duration-500 rounded bg-white p-6 border-white border-2 relative"
    :class="{ 'opacity-60 cursor-not-allowed pointer-events-none': !eligible }"
  >
    <span
      v-if="plan.recommended"
      class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-4 py-2 shadow rounded-full text-white text-xs bg-primary-600"
    >
      Recommended
    </span>

    <p class="text-3xl font-bold mt-4 mb-4">{{ plan.name }} Plan</p>
    <p v-if="plan.amount_cents" class="text-4xl font-bold">
      {{ formatPrice(plan.amount_cents / 100) }}

      <span class="text-gray-400 text-sm"> / month </span>
    </p>
    <p v-else class="text-gray-300 text-xl font-bold">Comming Soon</p>
    <p class="text-gray-600 my-4 max-w-md">
      {{ plan.description }}
    </p>

    <div class="flex flex-col gap-1 font-medium text-lg px-4">
      <div class="flex items-center my-2">
        <span class="text-3xl mr-2">📚</span>
        <div>
          Access to all document <span class="font-bold ml-1">templates</span>
          <div class="w-full text-sm">
            COCs, Solar Reports, Photo Reports, Annual Renewals and more
          </div>
        </div>
      </div>

      <div v-if="!tooManyUsers" class="flex flex-row items-center my-2">
        <span class="text-3xl mr-2">🙋‍♂️</span>
        <div>
          <span class="font-bold">{{
            plan.max_users > 0 ? plan.max_users : 'Unlimited'
          }}</span>
          team members
        </div>
      </div>
      <div v-else>
        <div class="flex flex-row items-center my-2">
          <span class="text-3xl mr-2">🙋‍♂️</span>
          <div class="text-red-800 font-bold">
            <span class="font-bold">{{
              plan.max_users > 0 ? plan.max_users : 'Unlimited'
            }}</span>
            team members
          </div>
        </div>
        <div class="w-full text-red-800 font-bold">
          You have {{ company?.attributes?.users?.data?.length }} team members,
          subscribe to the next plan or remove some team members to continue.
        </div>
      </div>
      <div class="flex flex-row items-center my-2">
        <span class="text-3xl mr-2">🔐</span>
        <div>
          <span class="font-bold">{{
            plan.max_documents_locked > 0
              ? plan.max_documents_locked
              : 'Unlimited'
          }}</span>
          documents locked
          <span
            v-if="plan.max_documents_locked > 0"
            class="font-bold opacity-50"
          >
            / per month</span
          >
        </div>
      </div>

      <div class="flex flex-row items-center my-2" v-if="getCharge(COCUID)">
        <span class="text-3xl mr-2">📝</span>
        <div>
          <span class="font-bold">{{ getIncludedUnits(COCUID) }}</span>
          COCs included
          <span class="font-bold opacity-50">
            / month, then
            {{ formatPrice(getAdditionalCharge(COCUID)) }} ea</span
          >
          <div class="w-full text-sm">Unlimited other documents</div>
        </div>
      </div>
      <div v-else-if="plan.max_documents_locked > 0">
        <span class="text-3xl mr-2">📝</span>
        <span class="font-bold">Up to {{ plan.max_documents_locked }}</span>
        COCs
        <span class="font-bold opacity-50"> / month </span>
      </div>

      <hr class="my-4" />
      <div class="font-bold text-center">Included in all plans</div>
      <div
        class="my-1"
        v-for="(feature, index) in standardFeatures"
        :key="index"
      >
        <div class="px-3 py-2 flex flex-row justify-between items-center">
          <div class="mr-2">✅</div>
          <div class="font-medium flex items-center flex-wrap justify-center">
            <span class="mr-2">{{ feature.emoji }}</span>
            <span class="text-center">{{ feature.title }}</span>
          </div>
        </div>
      </div>
    </div>

    <AppButton
      v-tippy="{
        content:
          'Download the app and set up your company before choosing a plan',
        placement: 'bottom'
      }"
      v-if="!company?.attributes"
      class="mt-4 opacity-50 cursor-not-allowed"
    >
      Choose plan
    </AppButton>
    <AppButton
      v-else-if="isCurrentPlan"
      disabled
      class="shadow-md mt-6 bg-green-500 border-green-500"
      >Current Plan</AppButton
    >

    <router-link v-else-if="eligible" :to="'/company/subscribe/' + plan.code">
      <AppButton class="mt-4"> Choose plan </AppButton>
    </router-link>

    <AppButton v-else disabled class="mt-4"> Choose plan </AppButton>
  </AppCard>
</template>
<script>
export default {
  data() {
    return {
      standardFeatures: [
        {
          emoji: '☁️',
          title: 'Cloud Storage & Backup'
        },
        {
          emoji: '🔒',
          title: 'Biometric Security'
        },
        {
          emoji: '📍',
          title: 'GPS Auto-fill for Location Data'
        }
      ],
      COCUID: '99938d8e-c340-49c7-88d8-a4078b46c50f'
    };
  },
  props: ['plan', 'currentSubscription', 'annually'],
  mounted() {
    this.$store.dispatch('Company/getCompany');
  },
  computed: {
    isCurrentPlan() {
      if (this.company?.attributes?.subscription_status !== 'active')
        return false;
      return this.company?.attributes?.plan_code == this.plan.code;
    },
    company() {
      return this.$store.getters['Company/company'];
    },
    tooManyUsers() {
      if (this.plan.max_users <= 0) return false;
      return (
        this.company?.attributes?.users?.data?.length > this.plan.max_users
      );
    },
    eligible() {
      return !this.tooManyUsers;
    }
  },

  methods: {
    getCharge(code) {
      return this.plan.charges.find(
        (charge) => charge.billable_metric_code === code
      );
    },
    getIncludedUnits(code) {
      const charge = this.getCharge(code);

      console.log(charge);

      if (!charge) {
        return 0;
      }

      return charge.properties.graduated_ranges[0].to_value;
    },
    getAdditionalCharge(code) {
      const charge = this.getCharge(code);

      if (!charge) {
        return 0;
      }

      return charge.properties.graduated_ranges[1].per_unit_amount;
    }
  }
};
</script>
