<template>
  <router-link :to="'/documents/templates/' + documentType.attributes.uid">
    <AppCard clickable class="w-96 !rounded-3xl">
      <div
        class="w-full bg-gray-100 py-0.5 rounded-md text-center flex justify-center items-center gap-2 font-bold text-sm"
      >
        <p class="text-primary-500">{{ documentType.attributes.name }}</p>
        <p
          v-if="documentType.attributes.archived"
          class="bg-white text-xs rounded-full px-1 py-0.5"
        >
          Archived
        </p>
      </div>
      <div class="w-full flex justify-around text-sm mt-4 font-bold gap-2">
        <p class="">Sections: {{ sectionCount }}</p>
        <p class="">Questions: {{ questionCount }}</p>
      </div>
    </AppCard>
  </router-link>
</template>
<script>
export default {
  props: {
    documentType: {}
  },
  computed: {
    sectionCount() {
      if (!this.documentType?.attributes?.structure) return 0;
      return Object.keys(this.documentType.attributes.structure)?.length;
    },
    questionCount() {
      if (!this.documentType?.attributes?.structure) return 0;

      let count = 0;

      Object.keys(this.documentType.attributes.structure).forEach((key) => {
        count += this.documentType.attributes.structure[key].questions?.length;
      });

      return count;
    }
  }
};
</script>

<!-- <template #subtitle>
  {{ documentType.attributes.subtitle }}
</template> -->
<!-- <template #chip>
  <span class="capitalize bg-gray-100 rounded-full px-3 py-1 " :class="{
    'bg-gray-200 text-primary-500': documentType.attributes.type === 'Global',
    'bg-primary-500 text-white': documentType.attributes.type === 'Company'
    
  }">{{ documentType.attributes.type }}</span>
</template> -->

<!-- <div class="flex justify-between p-4">
  <span
    class="bg-primary px-2 py-0.5 flex justify-center rounded-full text-white text-xs gap-1"
  >
    <span class="font-bold">Sections:</span>
    {{ sectionCount }}
  </span>

  <span class="font-bold">Questions:</span>
  {{ questionCount }}
</div> -->
