<template>
  <transition name="slide-up" mode="out-in" appear>
    <div class="flex w-full gap-8">
      <div class="fixed top-24">
        <AppButton type="secondary" icon="arrow-left" @click="$router.go(-1)">
          <IconPack type="ArrowLeft" class="w-4 h-4 mr-2" />
          Back
        </AppButton>
      </div>
      <div class="px-2 w-80 pb-72 h-screen overflow-y-scroll fixed">
        <AppTitle border class="mb-4">Structure Elements</AppTitle>
        <draggable
          class="list-group"
          tag="div"
          :component-data="{
            type: 'transition-group',
            name: !drag ? 'flip-list' : null
          }"
          :group="{ name: 'sections', pull: 'clone', put: false }"
          :clone="onClone"
          :list="sectionsPallet"
          v-bind="dragOptions"
          @start="drag = true"
          @end="drag = false"
          item-key="key"
        >
          <div
            v-for="element in sectionsPallet"
            :key="element.key"
            class="flex flex-wrap w-full"
          >
            <AppCard
              titleType="small"
              :icon="element.icon"
              class="my-1 w-full cursor-move"
            >
              <template #title>{{ element.name }}</template>
            </AppCard>
          </div>
        </draggable>
        <AppTitle border class="my-4 mt-8">Question Elements</AppTitle>
        <draggable
          :component-data="{
            type: 'transition-group',
            name: !drag ? 'flip-list' : null
          }"
          :group="{ name: 'questions', pull: 'clone', put: false }"
          :list="questionsPallet"
          v-bind="dragOptions"
          :clone="onClone"
          @start="drag = true"
          @end="drag = false"
          item-key="key"
        >
          <AppCard
            v-for="element in questionsPallet"
            :key="element.key"
            titleType="small"
            :icon="element.icon"
            class="w-full cursor-move my-2"
          >
            <template #title>
              <span class="capitalize">{{
                element.type.replace('_', ' ')
              }}</span>
            </template>
          </AppCard>
        </draggable>
      </div>
      <div class="w-80 shrink-0"></div>
      <div class="w-full max-w-screen-md mx-auto pb-20 relative">
        <AppTitle v-if="localDocumentType" type="page"
          >{{
            createMode
              ? 'Create Document Template'
              : 'Edit Document Template: ' + localDocumentType?.attributes.name
          }}
        </AppTitle>
        <TextInput
          v-if="localDocumentType"
          class="w-full my-8"
          placeholder="Enter Template Name"
          label="Template Name"
          v-model="localDocumentType.attributes.name"
          required
        />
        <AppCard collapsable open>
          <template #title>
            <div class="flex items-center">
              <IconPack type="InformationCircle" class="w-6 h-6 mr-2" />
              <p class="text-lg">Basic Info</p>
            </div>
          </template>
          <div
            v-if="localDocumentType?.attributes"
            class="my-4 flex-wrap gap-8 flex w-full items-center"
          >
            <!-- <div class="flex flex-col gap-1">
              <p class="text-primary-600 font-bold">Static Image</p>
              <p class="text-gray-400">
                Upload a static image for the document template. This image will
                be displayed at the top of each page.
              </p>
            </div> -->
            <!-- <FileUploadModal v-model="localDocumentType.attributes.logo" /> -->
            <div class="flex w-full items-center gap-8">
              <!-- <div class="w-full">
                <AppSwitch
                  class="my-4 text-sm"
                  v-model="localDocumentType.attributes.public"
                  leftLabel="Public"
                  rightLabel="Private"
                />
              </div> -->
              <div class="w-1/2">
                <div class="font-bold h-5 mb-2">
                  Document Category
                  <span class="text-red-500 font-bold">*</span>
                </div>
                <Multiselect
                  placeholder="Select Document Category"
                  class="h-10 !shadow-lg"
                  v-model="selectedCategory"
                  :options="categoryNames"
                />
              </div>
            </div>
          </div>
        </AppCard>
        <AppTitle border class="my-8">Document Sections</AppTitle>
        <draggable
          class="list-group"
          tag="div"
          :component-data="{
            type: 'transition-group',
            name: !drag ? 'flip-list' : null
          }"
          handle=".drag-handle"
          group="sections"
          :list="localStructure"
          :clone="onClone"
          v-bind="dragOptions"
          @start="drag = true"
          @end="drag = false"
          item-key="key"
        >
          <div
            v-for="(element, index) in localStructure"
            :key="element.key || index"
          >
            <SectionCard
              :ref="'section_' + element.key"
              :showKeys="showKeys"
              :key="element.key || index"
              :section="generateKey(element)"
              @updateSection="updateSection"
              @removeSection="removeSection"
              @cloneSection="cloneSection"
            />
          </div>
          <AppCard
            v-if="!localStructure || localStructure.length == 0"
            missing
            class="my-4 mx-4"
          >
            <template #title>Drop sections here</template>
          </AppCard>
        </draggable>
        <div class="w-full z-40 p-6 border-t bg-white fixed bottom-0 left-0">
          <div class="flex items-center justify-between">
            <transition name="scale-quick" mode="out-in" appear>
              <div
                v-if="unsavedChanges"
                class="flex ml-5 lg:ml-32 2xl:ml-72 gap-2 items-center"
              >
                <div
                  class="w-2 h-2 bg-secondary-500 dark:bg-slate-200 rounded-full animate-pulse"
                >
                  <div
                    class="w-2 h-2 bg-secondary-500 dark:bg-wh-primary-100 rounded-full animate-ping"
                  ></div>
                </div>
                <p>You have unsaved changes</p>
              </div>
            </transition>

            <div class="ml-auto w-fit mr-28 flex gap-5">
              <AppButton
                v-if="!createMode"
                class="!w-32"
                outline
                @click="
                  async () => {
                    savingPreview = true;
                    await saveTemplate(), (savingPreview = false);
                    $router.push({
                      name: 'DocumentDetails',
                      query: { structure: JSON.stringify(localStructure) }
                    });
                  }
                "
                :pulse="savingPreview"
                >Preview</AppButton
              >
              <AppButton
                class="!w-32 !h-fit"
                @click="
                  async () => {
                    savingTemplate = true;
                    await saveTemplate();
                    savingTemplate = false;
                    if (createMode) {
                      $router.push('/documents/templates');
                    }
                  }
                "
                :pulse="savingTemplate"
              >
                {{ createMode ? 'Create' : 'Save' }}
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import AppTitle from '../../../components/Dashboard/AppTitle.vue';
import SectionCard from '../../../components/Documents/Templates/SectionCard.vue';
import { VueDraggableNext } from 'vue-draggable-next';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import ConfirmDialog from '@/components/EW/ConfirmDialog.vue';
import { createConfirmDialog } from 'vuejs-confirm-dialog';
// import AppSwitch from '../../../components/EW/AppSwitch.vue';
// import FileUploadModal from '../../../components/Modals/FileUploadModal.vue';

export default {
  components: {
    draggable: VueDraggableNext,
    AppTitle,
    SectionCard
    // AppSwitch,
    // FileUploadModal
  },
  props: {
    uid: String
  },
  data() {
    return {
      selectedCategory: null,
      showKeys: false,
      drag: false,
      savingPreview: false,
      savingTemplate: false,
      sectionsPallet: [
        {
          title: 'Section name',
          name: 'Section',
          icon: 'Bars4',
          questions: []
        }
      ],
      questionsPallet: [
        {
          title: '',
          type: 'heading',
          icon: 'ChatBubbleLeft',
          required: false,
          allow_templating: false,
          validation: {}
        },
        {
          title: '',
          type: 'select',
          icon: 'Check',
          required: true,
          allow_templating: true,
          validation: {}
        },
        {
          title: '',
          type: 'text',
          icon: 'DocumentText',
          required: true,
          allow_templating: true,
          validation: {}
        },
        {
          title: '',
          type: 'date',
          icon: 'Calendar',
          required: true,
          allow_templating: true,
          validation: {}
        },
        {
          title: '',
          type: 'number',
          icon: 'Calculator',
          required: true,
          allow_templating: true,
          validation: {}
        },
        // {
        //   title: '',
        //   type: 'formula',
        //   icon: 'Beaker',
        //   hidden: true,
        //   required: true,
        //   allow_templating: true,
        //   validation: {}
        // },
        {
          title: '',
          type: 'image',
          icon: 'Photo',
          required: true,
          validation: {}
        },
        {
          title: '',
          type: 'product',
          icon: 'ArchiveBox',
          required: true,
          validation: {}
        },
        {
          title: '',
          type: 'signature',
          icon: 'Pencil',
          required: true,
          validation: {}
        },
        {
          title: 'Certificate number',
          type: 'certificate_number',
          icon: 'DocumentText',
          allow_templating: true,
          required: true,
          validation: {}
        },
        {
          title: '',
          type: 'credential',
          icon: 'Identification',
          allow_templating: true,
          required: true,
          validation: {}
        }
        //   {
        //   title: '',
        //   type: 'location',
        //   icon: 'Map',
        //   required: true
        // },
      ],
      localDocumentType: null,
      localStructure: []
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost'
      };
    },
    categories() {
      return this.$store.getters['Documents/categories'];
    },
    categoryNames() {
      return this.categories.map((c) => c.attributes?.title);
    },
    documentTypeUid() {
      return this.$route.params.uid;
    },
    documentType() {
      if (this.documentTypeUid === 'create') {
        return {
          attributes: {
            name: '',
            type: '',
            structure: []
          }
        };
      } else {
        return this.$store.getters['Documents/getTypeByUID'](
          this.documentTypeUid
        );
      }
    },
    createMode() {
      return this.documentTypeUid === 'create';
    },
    sectionCount() {
      return this.localStructure.length;
    },
    questionCount() {
      return this.localStructure.reduce(
        (count, section) => count + section.questions.length,
        0
      );
    },
    unsavedChanges() {
      // check if the local structure is different from the original structure
      return (
        !_.isEqual(
          this.localStructure,
          this.documentType?.attributes?.structure
        ) || !_.isEqual(this.localDocumentType, this.documentType)
      );
    }
  },
  beforeRouteLeave(to, from, next) {
    const { reveal, onConfirm } = createConfirmDialog(ConfirmDialog, {
      icon: 'ExclamationTriangle',
      title: 'Unsaved Changes',
      subtitle: 'You have unsaved changes',
      message: 'Do you want to leave without saving?',
      color: 'red'
    });

    if (this.unsavedChanges) {
      reveal();
      onConfirm(() => {
        next();
      });
    } else {
      next();
    }
  },

  watch: {
    documentType: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.localDocumentType = _.cloneDeep(newVal);
        this.localStructure = _.cloneDeep(newVal?.attributes.structure || []);
        // Ensure categories are loaded before setting the selectedCategory
        if (this.categories.length > 0) {
          this.selectedCategory =
            newVal?.attributes?.category?.data?.attributes?.title || null;
        }
      }
    }

    // selectedCategory(newVal) {
    //   const category = this.categories.find(
    //     (c) => c.attributes?.title === newVal
    //   );
    //   if (category) {
    //     this.localDocumentType.attributes.category = category;
    //   }
    // }
  },
  mounted() {
    this.$store.dispatch('Documents/getDocumentTemplates');
    this.$store.dispatch('Documents/getDocumentCategories');
  },
  methods: {
    handleBeforeUnload(event) {
      if (this.unsavedChanges) {
        const message =
          'You have unsaved changes. Do you really want to leave?';
        event.returnValue = message;
        return message;
      }
    },
    updateSection(section) {
      this.localStructure = this.localStructure.map((i) => {
        if (i.key === section.key) {
          return section;
        }
        return i;
      });
    },
    async saveTemplate() {
      // set dynamic attribute to true
      this.localDocumentType.attributes.dynamic = true;
      const documentTypeToSave = {
        ...this.localDocumentType.attributes,
        structure: this.localStructure
      };
      if (this.documentTypeUid === 'create') {
        // set type to company
        documentTypeToSave.type = 'Company';
        // Create a new document template
        await this.$store
          .dispatch('Documents/createDocumentTemplate', documentTypeToSave)
          .then(() => {
            this.$toast.success('Template created successfully');
          })
          .catch((err) => {
            console.error(err);
            this.toastApiErrors(err);
          });
      } else {
        // Update an existing document template
        await this.$store
          .dispatch('Documents/updateDocumentTemplate', {
            id: this.localDocumentType.id,
            attributes: documentTypeToSave
          })
          .then(() => {
            this.$toast.success('Template saved successfully', {
              duration: 3000,
              position: 'bottom'
            });
          })
          .catch((err) => {
            this.toastApiErrors(err);
          });
      }

      // refresh page after save
      await this.$store.dispatch('Documents/getDocumentTemplates');
    },
    removeSection(sectionKey) {
      const { reveal, onConfirm } = createConfirmDialog(ConfirmDialog, {
        icon: 'ExclamationTriangle',
        title: 'Remove Section',
        subtitle: 'Are you sure you want to remove this section?',
        message: `This action cannot be undone. All questions in this section will be lost.`,
        color: 'red'
      });

      reveal();

      onConfirm(() => {
        this.localStructure = this.localStructure.filter(
          (i) => i.key !== sectionKey
        );
      });
    },
    cloneSection(sectionKey) {
      let index = this.localStructure.findIndex((i) => i.key == sectionKey);
      let section = this.localStructure[index];
      let newSection = JSON.parse(JSON.stringify(section));
      newSection.key = uuid();
      this.localStructure.splice(index + 1, 0, newSection);
    },
    onClone(e) {
      return { ..._.cloneDeep(e), key: uuid() };
    },
    generateKey(e) {
      if (e.key) return e;
      e.key = uuid();
      return e;
    }
  }
};
</script>
